import './style.scss';

type UserAgreementProps = {
  textButton?: string;
};

const UserAgreement = (props: UserAgreementProps) => {
  const { textButton = 'Підтвердити' } = props;
  return (
    <div className="user-agreement">
      <p className="user-agreement__text">
        Натискаючи кнопку “{textButton}”, ви погоджуєтесь з умовами&nbsp;
        <a
          target="_blank"
          rel="noreferrer"
          href="https://paytech.com.ua/page-docs.html?tab=1"
          className="user-agreement__link"
        >
          Публічного договору
        </a>
        &nbsp;та&nbsp;
        <a
          target="_blank"
          rel="noreferrer"
          href="https://paytech.com.ua/page-docs.html?tab=2"
          className="user-agreement__link"
        >
          Політикою конфіденційності
        </a>
      </p>
    </div>
  );
};

export default UserAgreement;
